import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PsService } from './core/ps.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { DashboardPack, DashboardPlanPack, PackingOrder } from './core/pi';
import { MatDialog } from '@angular/material/dialog';
import { PackingDialogCreateTtnComponent } from './packing-dialog-create-ttn/packing-dialog-create-ttn.component';
import { PackingDialogOrderComponent } from './packing-dialog-order/packing-dialog-order.component';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { SrvService } from 'repositories';
import { AlertPackingDialogComponent } from '@crm/sale/delivery/packing/alert-packing-dialog/alert-packing-dialog.component';

@Component({
  selector: 'app-packing',
  templateUrl: './packing.component.html',
  styleUrls: ['./packing.component.scss'],
  providers: [PsService],
})
export class PackingComponent implements OnInit, OnDestroy {
  orderList$: Observable<PackingOrder[]>;
  orderListPack: PackingOrder[];
  selectedOrder: PackingOrder[];
  destroy$ = new ReplaySubject();
  triggerDashboard$ = new Subject();
  dashboard$: Observable<DashboardPack> = this.triggerDashboard$.pipe(
    startWith({ typeLoad: false, dateInfo: this.srv.convertDate(new Date()) }),
    switchMap((val) => this.ps.loadDashboard(val))
  );
  today = this.srv.convertDate(new Date());
  checked;
  checkedProd;
  tableHeaderInfo = {
    date: '',
  };

  loadPackDashboard$: Observable<DashboardPlanPack> = this.ps.loadPackDashboard();
  @ViewChild('checkOrder') private checkOrder: MatSlideToggle;

  constructor(
    private ps: PsService,
    public srv: SrvService,
    private router: Router,
    private title: Title,
    private dialog: MatDialog
  ) {
    this.selectedOrder = [];
    this.title.setTitle('Упаковка заказов');
  }

  ngOnInit(): void {
    this.getPackingOrderList();
  }

  loadDashboard(checked?, date?): void {
    this.today = date;
    this.triggerDashboard$.next({
      typeLoad: checked,
      dateInfo: this.srv.convertDate(date),
    });
  }

  getPackingOrderList(checked?, delivery?, setStep?, checkProd?): void {
    this.selectedOrder = [];
    this.tableHeaderInfo.date = this.today;
    this.orderList$ = this.ps
      .getPackingOrderList(checked, delivery, setStep, checkProd, this.today)
      .pipe(
        tap((val) => {
          this.orderListPack = val;
        })
      );
  }

  onPackingOrder(): any {
    if (this.selectedOrder.length === 0) {
      this.srv.errorMessage('Нет выбранных заказов');
      return false;
    }

    let np = 0;
    let noPack = '';
    let groupProdAvtonova = 0;
    let groupProdVavilon = 0;
    const orderList = [];
    this.selectedOrder.forEach((item) => {
      if (+item.deliveryType === 1) {
        np++;
      }
      if (+item.actionOrder !== 10) {
        noPack += ' ' + item.orderNumber;
      }
      if (+item.avtonova !== 0) {
        groupProdAvtonova++;
      }
      if (+item.vavilon !== 0) {
        groupProdVavilon++;
      }
      if (item) {
        orderList.push(item.orderNumber);
      }
    });

    if (np !== 0 && np !== this.selectedOrder.length) {
      this.srv.errorMessage('Типы перевозчиков не совпадают');
      return false;
    }
    if (noPack) {
      this.srv.errorMessage('Заказы ' + noPack + ' не   для упаковки');
      return false;
    }
    if (
      groupProdAvtonova !== 0 &&
      groupProdAvtonova !== this.selectedOrder.length
    ) {
      this.srv.errorMessage(
        'Выбранные заказы не все от поставщика 140 для упаковки'
      );
      return false;
    }
    if (
      groupProdVavilon !== 0 &&
      groupProdVavilon !== this.selectedOrder.length
    ) {
      this.srv.errorMessage(
        'Выбранные заказы не все от поставщика 110 для упаковки'
      );
      return false;
    }

    let dialog: any;

    if (np > 0) {
      dialog = this.dialog.open(PackingDialogCreateTtnComponent, {
        width: '100vw',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100vh',
        data: {
          orderList,
        },
      });
    } else {
      dialog = this.dialog.open(PackingDialogOrderComponent, {
        width: '95%',
        maxHeight: '90vh',
        data: {
          orderList,
        },
      });
    }

    dialog.afterClosed().subscribe((val) => {
      this.getPackingOrderList();
      this.selectedOrder = [];
    });
  }

  onRowSelect(event): void {
    console.log(event);
    this.ps
      .getAllPackOrder(
        event.data.phone,
        event.data.orderNumber,
        event.data.planDateSend
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        switch (+value.code) {
          case 200:
            let orderList = '';
            value.orderList.forEach(
              (item) => (orderList += item.zakaz + ' ' + item.action + ' ')
            );

            this.srv.errorMessage(
              'По получателю ' +
                event.data.recipient +
                ' есть еще совместные заказы ' +
                orderList
            );

            this.selectedOrder = [];
            break;
          case 202:
            const dialog = this.dialog.open(AlertPackingDialogComponent, {
              minWidth: '50vw',
              maxHeight: '90vh',
              data: {
                planDateSend: event.data.planDateSend,
                recipient: event.data.recipient,
                orderList: value.orderList,
              },
            });

            dialog
              .afterClosed()
              .pipe(filter(Boolean), takeUntil(this.destroy$))
              .subscribe(() => this.onPackingOrder());

            break;
          default:
            // this.selectedOrder.push(event.data);
            this.orderListPack.forEach((val) => {
              const crossOrder =
                val.crossOrder ??
                ({} as { orderNum: string; orderNumCross: string });

              const isFound =
                crossOrder.orderNum === val.orderNumber ||
                crossOrder.orderNumCross === val.orderNumber;

              if (
                val.phone === event.data.phone &&
                isFound &&
                val.orderNumber !== event.data.orderNumber
              ) {
                if (
                  !this.selectedOrder.find((order) => +order.id === +val.id)
                ) {
                  this.selectedOrder.push(val);
                }
              }
            });
            this.selectedOrder = this.selectedOrder.filter(
              (order, index, self) =>
                index === self.findIndex((o) => o.id === order.id)
            );

            let orders = '';
            this.selectedOrder.forEach((item) => {
              orders += ' ' + item.orderNumber + ',';
            });

            this.srv.successMessage(
              'По получателю ' +
                event.data.recipient +
                ' отмечены ' +
                orders +
                ' заказы'
            );
            break;
        }
      });
  }

  onRowUnselect(event): void {}

  printCheck(): any {
    if (this.selectedOrder.length === 0) {
      this.srv.errorMessage('Нет выбранных заказов');
      return false;
    }

    let np = 0;

    const newOrdNum: (string | number)[] = this.selectedOrder.map(
      (item: PackingOrder) => item.orderNumber
    );

    this.selectedOrder.forEach((item) => {
      if (+item.deliveryType === 1) {
        np++;
      }
    });

    if (np !== 0 && np !== this.selectedOrder.length) {
      this.srv.errorMessage('Типы перевозчиков не совпадают');
      return false;
    }

    // Бросаю на нужный Url
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['print', 'check'], {
        queryParams: { newOrdNum },
      })
    );
    window.open(url, '_blank');
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setTypeOrderList($event): void {
    this.checked = $event.checked;
    this.getPackingOrderList($event.checked);
    this.loadDashboard($event.checked);
  }

  setFilter(delivery?, setStep?): void {
    this.getPackingOrderList(this.checked, delivery, setStep, this.checkedProd);
  }

  setTypeProdList($event): void {
    this.checkedProd = $event.checked;
    this.getPackingOrderList(this.checked, '', '', this.checkedProd);
  }

  printNotPrintCheck(): boolean {
    const newOrdNum: (string | number)[] = [];
    this.orderListPack.forEach((item) => {
      if (+item.print !== 1) {
        newOrdNum.push(item.orderNumber);
      }
    });

    if (newOrdNum.length === 0) {
      this.srv.errorMessage('Нет чеков для печати');
      return false;
    }

    // Бросаю на нужный Url
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['print', 'check'], {
        queryParams: { newOrdNum },
      })
    );
    window.open(url, '_blank');
  }
}
